#login{
  background: rgba(139, 136, 136, 0.8);
  justify-content: center;
  display: flex;
  min-height: 100vh;
  align-items: center;
}

#form-login{
  max-width: 400px;
  padding: 30px 30px 40px;
  flex-grow: 1;
  background: rgba(21, 21, 21, 0.5);
}

.form-heading{
  font-size: 25px;
  color: #fff;
  margin: 30px;
}

.form-group{
  border: 1px solid #fff;
  margin-top: 15px;
  margin-bottom: 20px;
  display: flex;
}

.form-input{
  background: transparent;
  border: 0;
  outline: 0;
  color: #fff;
  flex-grow: 1;
}

.form-input::placeholder{
  color: #7451f8;
}

.form-submit{
  background: transparent;
  border: 1px solid #ece8ff;
  color: #ece8ff;
  width: 100%;
  text-transform: uppercase;
  transition: 0.5s ease-in-out;
}

.form-submit:hover{
  border: 1px solid #b7306a;
}


